export const CurrentSeason = 2;
export const Seasons = [
    {
        season: 1,
        modpack: {
            name: 'BounceSMP Public',
            url: 'https://www.curseforge.com/minecraft/modpacks/bouncesmp-public',
        },
    },
    {
        season: 2,
        modpack: {
            name: 'Valhelsia 5',
            url: 'https://www.curseforge.com/minecraft/modpacks/valhelsia-5',
        },
    },
];
