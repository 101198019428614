import { Box, Card, CardContent, CardHeader, CardMedia, Typography } from '@mui/material';
import { LinkSite, MemberLinkHandle } from '../config/members';
import { currentMemberPageSignal } from '../signals/currentMember.signal';
import { currentMembersTwitchStatusesSignal, TwitchStatusType } from '../signals/currentMembersTwitchStatuses';

function BaseStatusCard(props: { children: any; sx?: any; disableHeader?: boolean }) {
    return (
        <Box>
            <Card sx={props.sx}>
                {props.disableHeader ? null : <CardHeader title="Twitch Status" />}
                {props.children}
            </Card>
        </Box>
    );
}
export default function MemberTwitchStatus() {
    const member = currentMemberPageSignal.value;
    const twitchStatuses = currentMembersTwitchStatusesSignal.value;

    if (member === null) return <div>Member Does Not Exist</div>;

    const doesCurrentMemberHaveTwitch = member.links?.find((link) => link.linkSite === LinkSite.Twitch) || undefined;
    const currentMemberTwitchStatus =
        doesCurrentMemberHaveTwitch !== undefined
            ? twitchStatuses?.find((twitchStatus) => twitchStatus.user_login === (doesCurrentMemberHaveTwitch as MemberLinkHandle).handle) || null
            : null;

    if (doesCurrentMemberHaveTwitch === undefined) return <></>;

    if (currentMemberTwitchStatus === null)
        return <BaseStatusCard>{currentMemberTwitchStatus !== null ? 'Currently Live on Twitch!' : 'Not Currently Live on Twitch'}</BaseStatusCard>;

    return (
        <BaseStatusCard>
            <CardMedia
                component="img"
                sx={{ height: 152 }}
                src={currentMemberTwitchStatus.thumbnail_url.replace('{width}', '1280').replace('{height}', '720')}
                alt={currentMemberTwitchStatus.title}
            />
            <CardContent>
                <Box>
                    <Typography color="text.secondary" gutterBottom>
                        {currentMemberTwitchStatus.title}
                    </Typography>
                </Box>
            </CardContent>
        </BaseStatusCard>
    );
}
