import { Box, Button, Card, CardActions, CardContent, CardHeader, Stack, Typography, Unstable_Grid2 as Grid } from '@mui/material';

import { LinkSite, MemberLinkHandle, MemberLinkURL, MemberRoles, MemberType, createMemberLinkURL } from '../config/members';
import MemberAvatar from './MemberAvatar';
import { currentMemberPageSignal } from '../signals/currentMember.signal';

import MemberTwitchStatus from './MemberTwitchStatus';

export default function MemberPage(props: { member: MemberType | null }) {
    const member = props.member;
    if (member === null) return <div>Member Does Not Exist</div>;

    return (
        <Box>
            <Button
                onClick={() => {
                    currentMemberPageSignal.value = null;
                }}
            >
                Back
            </Button>
            <Card sx={{ minWidth: 100 }} className="member-card" elevation={2}>
                <CardHeader
                    avatar={<MemberAvatar member={member} />}
                    title={<Typography variant="h6">{member.username}</Typography>}
                    subheader={'Joined ' + member.dateJoined.toLocaleString()}
                    sx={{ mb: -2 }}
                />
                <CardContent>
                    <Grid container direction={{ xs: 'column', md: 'row' }} columns={{ xs: 1, md: 4 }} spacing={{ xs: 2 }} justifyContent="center">
                        <Grid xs={1} md={2}>
                            <Card sx={{ minWidth: 270 }} className="member-card" elevation={4}>
                                <CardHeader title="About" />
                                <CardContent>
                                    <Typography color="text.secondary" gutterBottom>
                                        {member.roles.map((role, index) => (
                                            <span>
                                                {index > 0 ? ', ' : ''}
                                                {MemberRoles[role]}
                                            </span>
                                        ))}
                                    </Typography>

                                    {member.aliases && member.aliases.length > 0 ? (
                                        <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                                            Aliases:{' '}
                                            {member.aliases.map((alias, index) => (
                                                <span>
                                                    {index > 0 ? ', ' : ''}
                                                    {alias}
                                                </span>
                                            ))}
                                        </Typography>
                                    ) : (
                                        ''
                                    )}
                                    <Typography sx={{ mb: -2 }}>{member.bio}</Typography>
                                </CardContent>
                                <CardActions sx={{ justifyContent: 'center' }}>
                                    <Stack
                                        direction={{ xs: 'column', md: 'row' }}
                                        spacing={{ xs: 1, md: 2 }}
                                        justifyContent={{ xs: 'center', md: 'space-between' }}
                                        alignItems={{ xs: 'stretch', md: 'center' }}
                                        useFlexGap
                                        flexWrap="wrap"
                                    >
                                        {member.links.map((link) => (
                                            <Button href={createMemberLinkURL(link)} size="medium" variant="text" style={{ color: link.color }}>
                                                {link.label || LinkSite[link.linkSite]}
                                            </Button>
                                        ))}
                                    </Stack>
                                </CardActions>
                            </Card>
                        </Grid>
                        <Grid xs={1} md={2}>
                            <Card sx={{ minWidth: 270 }} className="member-card" elevation={4}>
                                <CardHeader title="Recent Content" />
                                <CardContent>
                                    <Typography color="text.secondary" gutterBottom>
                                        More details will be displayed here soon!
                                    </Typography>

                                    <MemberTwitchStatus />
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Box>
    );
}
