import {
    Box,
    Button,
    Card,
    CardActionArea,
    CardActions,
    CardContent,
    CardHeader,
    MobileStepper,
    Skeleton,
    Typography,
    useTheme,
    Unstable_Grid2 as Grid,
    Link,
    FormControlLabel,
    FormGroup,
} from '@mui/material';

import SwipeableViews from 'react-swipeable-views';

import { Suspense, lazy, useState } from 'preact/compat';

import { MemberRoles, MemberType, members } from './config/members';
import { CurrentSeason, Seasons } from './config/config';
import MemberAvatar from './components/MemberAvatar';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

import ReactSkinview3d from 'react-skinview3d';
import { IdleAnimation, SkinViewer } from 'skinview3d';

import MemberPage from './components/MemberPage';
import { currentMemberPageSignal } from './signals/currentMember.signal';
import { CheckBox } from '@mui/icons-material';

// @ts-ignore
const _aboutMessageMDX = lazy(() => import('./config/aboutMessage.mdx'));
const AboutMessageMDX = () => (
    <Suspense
        fallback={
            <Box sx={{ mt: 2 }}>
                <Skeleton variant="rounded" />
                <Skeleton />
            </Box>
        }
    >
        <_aboutMessageMDX />
    </Suspense>
);

export default function App() {
    const [showAllMembers, setShowAllMembers] = useState(false);

    const currentSeasonData = Seasons.find((season) => season.season === CurrentSeason);
    if (!currentSeasonData) throw new Error('Current season data not found');
    const currentSeasonMembers = showAllMembers ? members : members.filter((member) => member.seasonsPresent.includes(CurrentSeason));
    if (currentSeasonMembers.length === 0) throw new Error('No members found for current season');

    const theme = useTheme();
    const [activeStep, setActiveStep] = useState(0);
    const maxSteps = currentSeasonMembers.length;

    function handleNext() {
        if (activeStep + 1 === maxSteps) {
            setActiveStep(0);
            return;
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

    function handleBack() {
        if (activeStep === 0) {
            setActiveStep(maxSteps - 1);
            return;
        }
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }

    // This is so that we only render the skin currently being displayed,
    //   and the ones before and after so theres no visual glitching when the user goes between each member
    // For some reason things start to break if too many skins are being rendered at once so thats why this is here
    function decideToRenderSkin(memberIndex: number) {
        if (activeStep === memberIndex) return true;
        if (activeStep + 1 === memberIndex) return true;
        if (activeStep - 1 === memberIndex) return true;

        return false;
    }

    return (
        <>
            <Box sx={{ display: currentMemberPageSignal.value !== null ? '' : 'none' }}>
                <MemberPage member={currentMemberPageSignal.value} />
            </Box>
            <Box>
                <Grid container spacing={2} justifyContent="center">
                    <Grid xs={10}>
                        <Typography variant="h3" sx={{ mt: 2 }}>
                            About
                        </Typography>
                        <Typography variant="body1">
                            <AboutMessageMDX />
                        </Typography>
                        <Typography variant="body1">
                            Current Season: {CurrentSeason}
                            <br />
                            MineCraft Version: <Link href={currentSeasonData.modpack.url}>{currentSeasonData.modpack.name}</Link>
                        </Typography>
                        <Typography sx={{ mt: 4 }} variant="h3" justifySelf="center">
                            Members
                        </Typography>
                        <Typography variant="body1">There are currently {currentSeasonMembers.length} members in the server this season.</Typography>

                        <Button
                            onClick={() => {
                                setShowAllMembers(!showAllMembers);
                            }}
                        >
                            Show Members From Past Seasons
                        </Button>

                        <Box sx={{ maxWidth: 1000, flexGrow: 1, mt: 4 }}>
                            <MobileStepper
                                variant="text"
                                steps={maxSteps}
                                position="static"
                                activeStep={activeStep}
                                sx={{ flexGrow: 1, bgcolor: theme.palette.background.paper }}
                                classes={{ root: 'member-mobile-stepper' }}
                                nextButton={
                                    <Button size="small" onClick={handleNext} disabled={activeStep === maxSteps}>
                                        Next
                                        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                                    </Button>
                                }
                                backButton={
                                    <Button size="small" onClick={handleBack}>
                                        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                                        Back
                                    </Button>
                                }
                            />
                            <SwipeableViews axis={'x'} index={activeStep} onChangeIndex={setActiveStep} enableMouseEvents>
                                {currentSeasonMembers.map((member, memberIndex) => (
                                    <Card key={member.username} sx={{ minWidth: 100 }} className="member-card" elevation={2}>
                                        <CardHeader
                                            title={<Typography variant="h6">{member.username}</Typography>}
                                            subheader={'Joined ' + member.dateJoined.toLocaleString()}
                                            sx={{ mb: -2 }}
                                        />
                                        <Grid
                                            container
                                            direction={{ xs: 'column', md: 'row' }}
                                            columns={{ xs: 1, md: 4 }}
                                            spacing={{ xs: 2 }}
                                            justifyContent="center"
                                        >
                                            <Grid xs={1} md={2}>
                                                <CardContent>
                                                    <Box
                                                        sx={{
                                                            height: 400,
                                                            display: 'flex',
                                                            maxWidth: 800,
                                                            overflow: 'hidden',
                                                            width: '100%',
                                                            justifyContent: 'center',
                                                        }}
                                                    >
                                                        {member.skinTexture && decideToRenderSkin(memberIndex) ? (
                                                            <ReactSkinview3d
                                                                skinUrl={member.skinTexture}
                                                                height="400"
                                                                width="200"
                                                                onReady={({ viewer }: { viewer: SkinViewer }) => {
                                                                    viewer.controls.enableRotate = false;
                                                                    viewer.controls.enableZoom = false;
                                                                    viewer.controls.enablePan = false;

                                                                    viewer.animation = new IdleAnimation();

                                                                    viewer.camera.position.setX(-12.043279210662355);
                                                                    viewer.camera.position.setY(11.890029699207485);
                                                                    viewer.camera.position.setZ(38);

                                                                    // Disable auto rotate
                                                                    viewer.autoRotate = false;
                                                                }}
                                                            />
                                                        ) : (
                                                            <MemberAvatar widthOneHundred member={member} />
                                                        )}
                                                    </Box>
                                                </CardContent>
                                            </Grid>
                                            <Grid xs={1} md={2}>
                                                <CardContent>
                                                    <Card sx={{ minWidth: 250 }} className="member-card" elevation={4}>
                                                        <CardActionArea
                                                            onClick={() => {
                                                                currentMemberPageSignal.value = member;
                                                            }}
                                                        >
                                                            <CardHeader
                                                                title="About"
                                                                subheader={member.roles.map((role, index) => (
                                                                    <span>
                                                                        {index > 0 ? ', ' : ''}
                                                                        {MemberRoles[role]}
                                                                    </span>
                                                                ))}
                                                                sx={{ mb: -2 }}
                                                            />
                                                            <CardContent>
                                                                <Typography variant="subtitle2" color="text.secondary">
                                                                    Seasons Present: {member.seasonsPresent.toString()}
                                                                </Typography>
                                                                {member.aliases && member.aliases.length > 0 ? (
                                                                    <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                                                                        Aliases:{' '}
                                                                        {member.aliases.map((alias, index) => (
                                                                            <span>
                                                                                {index > 0 ? ', ' : ''}
                                                                                {alias}
                                                                            </span>
                                                                        ))}
                                                                    </Typography>
                                                                ) : (
                                                                    ''
                                                                )}
                                                                <Typography sx={{ mt: 1 }} variant="body1" color="text.secondary">
                                                                    {member.bio}
                                                                </Typography>
                                                            </CardContent>
                                                        </CardActionArea>
                                                        <CardActions>
                                                            <Button
                                                                onClick={() => {
                                                                    currentMemberPageSignal.value = member;
                                                                }}
                                                                size="medium"
                                                                variant="text"
                                                                sx={{ mt: 1, width: '100%' }}
                                                            >
                                                                Visit {member.username}'s Page
                                                            </Button>
                                                        </CardActions>
                                                    </Card>
                                                </CardContent>
                                            </Grid>
                                        </Grid>
                                    </Card>
                                ))}
                            </SwipeableViews>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
}
