import { render } from 'preact';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import ThemeWrapper from './components/ThemeWrapper';
import App from './App';

const mainRender = (
    <ThemeWrapper>
        <App />
    </ThemeWrapper>
);

const documentApp = document.getElementById('app');
if (!documentApp) {
    console.error('What the fuck? theres no app element? wtf?');
    throw new Error('God is dead and we have killed him');
}

render(mainRender, documentApp);
