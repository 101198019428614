export enum MemberRoles {
    Member,
    Admin,
    Owner,
    WebsiteManager,
}

export enum LinkSite {
    Twitch,
    Discord,
    YouTube,
    Instagram,
    Twitter,
    Website,
    Other,
}

export type MemberLinkBase = {
    label?: string;
    color?: string;
    icon?: string;
};

export interface MemberLinkURL extends MemberLinkBase {
    url: string;
    linkSite: LinkSite.Discord | LinkSite.Website | LinkSite.Other;
}

export interface MemberLinkHandle extends MemberLinkBase {
    handle: string;
    linkSite: LinkSite.Twitch | LinkSite.YouTube | LinkSite.Instagram | LinkSite.Twitter;
}

export type MemberLinkType = MemberLinkURL | MemberLinkHandle;

export type MemberType = {
    username: string;

    avatar: string;
    skinTexture?: string;

    roles: MemberRoles[];
    dateJoined: Date;
    dateLeft: Date | null;
    seasonsPresent: number[];

    aliases?: string[];
    bio: string;
    links: MemberLinkType[];
};

export function createMemberLinkURL(link: MemberLinkType): string {
    switch (link.linkSite) {
        case LinkSite.Twitch:
            return `https://twitch.tv/${link.handle}`;
        case LinkSite.Discord:
            return link.url;
        case LinkSite.YouTube:
            return `https://youtube.com/${link.handle}`;
        case LinkSite.Instagram:
            return `https://instagram.com/${link.handle}`;
        case LinkSite.Twitter:
            return `https://twitter.com/${link.handle}`;
        case LinkSite.Website:
            return link.url;
        case LinkSite.Other:
            return link.url;
        default: {
            return 'https://unitysmp.com/404';
        }
    }
}

const defaultAvatar = new URL('../logos/UnitySMP_Logo.png', import.meta.url).href;
const defaultSkin = new URL('../logos/UnitySMP_Logo.png', import.meta.url).href;
export const members: MemberType[] = [
    {
        username: 'JawbreakerSD',
        avatar: defaultAvatar,
        skinTexture: new URL('../logos/jawbreakersd_skin.png', import.meta.url).href,
        roles: [MemberRoles.Owner, MemberRoles.Member],
        dateJoined: new Date('July 10, 2023 11:00'),
        dateLeft: null,
        seasonsPresent: [1, 2],
        bio: '',
        links: [
            {
                linkSite: LinkSite.YouTube,
                handle: '@JawbreakerSD',
                label: 'YouTube',
            },
            {
                linkSite: LinkSite.Twitch,
                handle: 'jawbreakersd',
                label: 'Twitch',
                color: '#6441a5',
            },
        ],
    },
    {
        username: 'Flameborne',
        avatar: new URL('../logos/flameborne_profile.png', import.meta.url).href,
        skinTexture: new URL('../logos/flameborne_skin.png', import.meta.url).href,
        roles: [MemberRoles.Member],
        dateJoined: new Date('July 10, 2023 11:00'),
        aliases: ['Flame'],
        dateLeft: null,
        seasonsPresent: [1, 2],
        bio: 'Just tryna have fun playing video games. Can sometimes lead to questionable jokes, so enter at your own risk.',
        links: [
            {
                linkSite: LinkSite.YouTube,
                handle: '@flameb0rne',
                label: 'YouTube',
            },
            {
                linkSite: LinkSite.Twitch,
                handle: 'flameb0rne',
                label: 'Twitch',
                color: '#6441a5',
            },
            {
                linkSite: LinkSite.Instagram,
                handle: 'flameb0rne',
                label: 'Instagram',
                color: '#e1306c',
            },
            {
                linkSite: LinkSite.Discord,
                url: 'https://discord.gg/TfPYvWDCEZ',
                label: 'Discord Server',
                color: '#7289da',
            },
        ],
    },
    {
        username: 'RynoTheRaccy',
        aliases: ['Ryno'],
        avatar: defaultAvatar,
        skinTexture: new URL('../logos/ryno_skin.png', import.meta.url).href,
        roles: [MemberRoles.Member],
        dateJoined: new Date('July 10, 2023 11:00'),
        dateLeft: null,
        seasonsPresent: [1, 2],
        bio: '',
        links: [
            {
                linkSite: LinkSite.YouTube,
                handle: '@rynotheraccy',
                label: 'YouTube',
            },
        ],
    },
    {
        username: 'Foogi',
        aliases: ['Foogi6697'],
        avatar: defaultAvatar,
        skinTexture: new URL('../logos/foogi_skin.png', import.meta.url).href,
        roles: [MemberRoles.Member],
        dateJoined: new Date('July 10, 2023 11:00'),
        dateLeft: null,
        seasonsPresent: [1, 2],
        bio: '',
        links: [
            {
                linkSite: LinkSite.YouTube,
                handle: '@foogi6697',
                label: 'YouTube',
            },
        ],
    },
    {
        avatar: 'https://insberr.com/assets/insberr_avatar.png',
        skinTexture: new URL('../logos/insberr_skin.png', import.meta.url).href,
        roles: [MemberRoles.Member, MemberRoles.WebsiteManager],
        dateJoined: new Date('July 10, 2023 11:00'),
        dateLeft: null,
        seasonsPresent: [1, 2],
        username: 'insberr',
        bio: 'I am a programmer and Youtuber. I waste a lot of time and made this website. I am happy to be a part of this new SMP! Hopefully my YouTube channel will grow.',
        links: [
            {
                linkSite: LinkSite.YouTube,
                handle: '@insberr.gaming',
                label: 'YouTube',
            },
            {
                linkSite: LinkSite.Twitch,
                handle: 'insberr',
                label: 'Twitch',
                color: '#6441a5',
            },
            {
                linkSite: LinkSite.Website,
                url: 'https://insberr.com/',
                label: 'Website',
                color: '#00ff00',
            },
        ],
    },
    {
        username: 'Canadian_God',
        aliases: ['Canada'],
        avatar: new URL('../logos/canadian_profile.webp', import.meta.url).href,
        skinTexture: new URL('../logos/canadian_skin.png', import.meta.url).href,
        roles: [MemberRoles.Member],
        dateJoined: new Date('July 10, 2023 11:00'),
        dateLeft: null,
        seasonsPresent: [1, 2],
        bio: 'A fellow canadian ready to have some fun and adventures',
        links: [
            {
                linkSite: LinkSite.YouTube,
                handle: '@Canadian_God',
                label: 'YouTube',
            },
        ],
    },
    {
        username: 'Shreked',
        avatar: defaultAvatar,
        skinTexture: new URL('../logos/shreked_skin.png', import.meta.url).href,
        roles: [MemberRoles.Member],
        dateJoined: new Date('July 10, 2023 11:00'),
        dateLeft: new Date('May 1, 2024 11:00'), // unknown left date
        seasonsPresent: [1],
        bio: '',
        links: [
            {
                linkSite: LinkSite.YouTube,
                handle: '@RealShrekTheOgre',
                label: 'YouTube',
            },
        ],
    },
    {
        username: 'PRINCE HOTROD',
        avatar: defaultAvatar,
        skinTexture: new URL('../logos/prince_skin.png', import.meta.url).href,
        roles: [MemberRoles.Member],
        dateJoined: new Date('July 10, 2023 11:00'),
        bio: '',
        links: [
            {
                linkSite: LinkSite.YouTube,
                handle: '@PRINCEHOTROD',
                label: 'YouTube',
            },
        ],
        dateLeft: null,
        seasonsPresent: [1, 2],
    },
    {
        username: 'RashPlayz',
        aliases: ['Rash'],
        avatar: new URL('../logos/rashplayz_profile.png', import.meta.url).href,
        skinTexture: new URL('../logos/rashplayz_skin.jpg', import.meta.url).href,
        roles: [MemberRoles.Member],
        dateJoined: new Date('July 10, 2023 11:00'),
        bio: '',
        links: [
            {
                linkSite: LinkSite.YouTube,
                handle: '@Rash-Playz',
                label: 'YouTube',
            },
        ],
        dateLeft: null,
        seasonsPresent: [1, 2],
    },
    {
        username: 'Attilary',
        aliases: ['Attila'],
        avatar: defaultAvatar,
        skinTexture: new URL('../logos/attilary_skin.png', import.meta.url).href,
        roles: [MemberRoles.Admin, MemberRoles.Member],
        dateJoined: new Date('July 10, 2023 11:00'),
        bio: '',
        links: [
            {
                linkSite: LinkSite.YouTube,
                handle: '@attilary',
                label: 'YouTube',
            },
        ],
        dateLeft: null,
        seasonsPresent: [1, 2],
    },
    {
        username: 'AlphaFennec',
        aliases: [],
        avatar: defaultAvatar,
        skinTexture: new URL('../logos/alpha_skin.png', import.meta.url).href,
        roles: [MemberRoles.Member],
        dateJoined: new Date('July 10, 2023 11:00'),
        bio: '',
        links: [
            {
                linkSite: LinkSite.YouTube,
                handle: '@AlphaFennec',
                label: 'YouTube',
            },
        ],
        dateLeft: null,
        seasonsPresent: [1, 2],
    },
    {
        username: 'C0D',
        avatar: new URL('../logos/cod_profile.png', import.meta.url).href,
        skinTexture: new URL('../logos/cod_skin.png', import.meta.url).href,
        roles: [MemberRoles.Member],
        dateJoined: new Date('July 10, 2023 11:00'),
        bio: '',
        links: [],
        dateLeft: new Date('May 1, 2024 11:00'), // unknown
        seasonsPresent: [1],
    },
    {
        username: 'pepo',
        avatar: defaultAvatar,
        skinTexture: defaultSkin,
        roles: [MemberRoles.Member],
        dateJoined: new Date('July 10, 2023 11:00'),
        bio: '',
        links: [
            {
                linkSite: LinkSite.YouTube,
                handle: '@LesTross',
                label: 'YouTube',
            },
        ],
        dateLeft: null,
        seasonsPresent: [1, 2],
    },
    {
        username: '123Potato',
        avatar: defaultAvatar,
        skinTexture: new URL('../logos/potato_skin.png', import.meta.url).href,
        roles: [MemberRoles.Member],
        dateJoined: new Date('July 10, 2023 11:00'),
        bio: '',
        links: [
            {
                linkSite: LinkSite.Twitch,
                handle: 'propotatoess',
                label: 'Twitch',
                color: '#6441a5',
            },
        ],
        dateLeft: null,
        seasonsPresent: [1, 2],
    },
    {
        username: 'SkulkerBox',
        aliases: ['Skulker'],
        avatar: new URL('../logos/skulkerbox_profile.png', import.meta.url).href,
        skinTexture: new URL('../logos/skulker_skin.png', import.meta.url).href,
        roles: [MemberRoles.Member],
        dateJoined: new Date('July 10, 2023 11:00'),
        bio: 'I’m a builder who can be a bit of a devil to my friends, I also don’t really upload and appear in there stuff a lot more',
        links: [
            {
                linkSite: LinkSite.YouTube,
                handle: '@SkulkerBoxx',
                label: 'YouTube',
            },
            {
                linkSite: LinkSite.Twitch,
                handle: 'skulkerbox',
                label: 'Twitch',
                color: '#6441a5',
            },
        ],
        dateLeft: null,
        seasonsPresent: [1, 2],
    },
    {
        username: 'PenguinNitr0',
        aliases: ['Nitr0'],
        avatar: defaultAvatar,
        skinTexture: new URL('../logos/nitr0_skin.png', import.meta.url).href,
        roles: [MemberRoles.Member],
        dateJoined: new Date('August 13, 2023 11:00'),
        bio: 'i try to get along with most people. also poopy.',
        links: [{ linkSite: LinkSite.YouTube, handle: 'https://www.youtube.com/channel/UC8xdOL5zp-VUxGYJGaUbIpA', label: 'YouTube' }],
        dateLeft: null,
        seasonsPresent: [1, 2],
    },
    {
        username: 'FatamusBalamus2',
        aliases: ['Fatamus', 'Ballamus', 'Fat Balls'],
        avatar: defaultAvatar,
        skinTexture: new URL('../logos/fatumus_skin.png', import.meta.url).href,
        roles: [MemberRoles.Member],
        dateJoined: new Date('August 27, 2023 11:00'),
        bio: 'Yo what up it me Fatamus/Fat Balls. I make content on YouTube, good content in fact (I think). I make video essays, gaming videos, and skits. I also stream every now and then which is cool',
        links: [
            { linkSite: LinkSite.YouTube, handle: '@fatamusballamus6004', label: 'YouTube' },
            { linkSite: LinkSite.Twitch, handle: 'fatamusballamus', label: 'Twitch', color: '#6441a5' },
        ],
        dateLeft: null,
        seasonsPresent: [1, 2],
    },
    {
        username: 'mrbutternub02',
        aliases: ['Butternub'],
        avatar: new URL('../logos/mrbutternub_profile.jpg', import.meta.url).href,
        skinTexture: new URL('../logos/mrbutternub_skin.png', import.meta.url).href,
        roles: [MemberRoles.Member],
        dateJoined: new Date('August 21, 2023 11:00'),
        bio: 'Very loud streamer who is not afraid to scream at his fellow streamers using “funny mic”. Also the best plant main in michigan',
        links: [
            { linkSite: LinkSite.YouTube, handle: '@mrbutternub02', label: 'YouTube' },
            { linkSite: LinkSite.Twitch, handle: 'MrButternub02', label: 'Twitch', color: '#6441a5' },
        ],
        dateLeft: null,
        seasonsPresent: [1, 2],
    },
    {
        username: 'CosmicBlossom',
        aliases: [],
        avatar: defaultAvatar,
        skinTexture: new URL('../logos/cosmicblossom_skin.png', import.meta.url).href,
        roles: [MemberRoles.Member],
        dateJoined: new Date(''), // Unknown
        bio: 'A cozy but also chaotic YouTube VTuber with a unhealthy obsession with aesthetics.If you like Minecraft and VTubers why not stop by',
        links: [],
        dateLeft: null,
        seasonsPresent: [1, 2],
    },
];
