import { signal } from '@preact/signals';
import { LinkSite, MemberLinkHandle, members } from '../config/members';

export type TwitchStatusType = {
    user_login: string;
    user_id: string;
    game_id: string;
    type: string;
    title: string;
    viewer_count: number;
    started_at: string;
    language: string;
    thumbnail_url: string;
}[];

export const currentMembersTwitchStatusesSignal = signal<TwitchStatusType | null>(null);

fetch(
    '/memberTwitch' +
        members
            .map((member) => {
                const memberTwitchLink = member.links.find((link) => link.linkSite === LinkSite.Twitch);
                if (memberTwitchLink !== undefined) {
                    return '/' + (memberTwitchLink as MemberLinkHandle).handle;
                }
                return '';
            })
            .join('')
)
    .then((response) => {
        response
            .json()
            .then((data) => {
                currentMembersTwitchStatusesSignal.value = data.data;
            })
            .catch((err) => {
                console.log(err);
            });
    })
    .catch((err) => {
        console.log(err);
        currentMembersTwitchStatusesSignal.value = null;
    });
