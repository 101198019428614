import { Avatar } from '@mui/material';
import { MemberType } from '../config/members';
import { red } from '@mui/material/colors';

export default function MemberAvatar(props: { member: MemberType; widthOneHundred?: boolean }) {
    const { member } = props;
    return (
        <Avatar
            src={member.avatar}
            sx={{ bgcolor: red[300], width: props.widthOneHundred ? '100%' : 56, height: props.widthOneHundred ? 'auto' : 56 }}
            alt={member.username}
            aria-label={member.username + '-avatar'}
        >
            {member.username[0]}
        </Avatar>
    );
}
